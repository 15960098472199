import './styles/index.css';

function ActivateNavigationItems() {
  // based on: https://codepen.io/zbymhz/pen/ExjRNwZ
  // hightlights the menu items as their content is visible on the page

  var topMenu = $("header nav");
  var topMenuHeight = topMenu.outerHeight() + 1;
  var menuItems = topMenu.find("a");
  var contentItems = menuItems.map(function () {
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
  });

  menuItems.click(function (e) {
    // scroll to content
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 500);
    e.preventDefault();
  });

  var lastId;
  $(window).scroll(function () {
    // control scrolling
    // get content item scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // get current content item
    var contentAbove = contentItems.map(function () {
      var itemTop = $(this).offset().top;
      if (itemTop < fromTop)
        return this;
    });
    var current = contentAbove[contentAbove.length - 1];

    // get the id of the current content
    var id = current && current.length ? current[0].id : "";

    if (lastId !== id) {
      lastId = id;
      // update active class
      menuItems.parent().removeClass("active");
      menuItems.filter("[href='#" + id + "']").parent().addClass("active");
    }
  });
}

$(ActivateNavigationItems);
